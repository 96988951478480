import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar as MuiAppBar, Toolbar, Container, Button, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import AppContext from './AppContext';
import Apartment from './classes/Apartment';

interface Props {
  onRequest: () => void;
}

export default function AppBar({ onRequest }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { apartments, selectedApartment, start, end, setSelectedApartment, setStart, setEnd, local } = React.useContext(AppContext);

  React.useEffect(() => {
    const newApartment = apartments.find((apartment: Apartment) => location.pathname.includes(apartment.key));
    if (newApartment) {
      setSelectedApartment(newApartment);
    }
  }, [apartments, location, setSelectedApartment]);

  const onChangeApartment = (e: any) => {
    const newApartment = apartments.find((apartment: Apartment) => apartment.key === e.target.value);
    navigate(`/${newApartment?.key}`);
  };

  return (
    <MuiAppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, pt: 1, pb: 1 }}>
      <Container maxWidth={false}>
        <Toolbar>
          <Grid container item spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={2} xl={2}>
              <Button
                fullWidth
                sx={{
                  height: '75px',
                  backgroundImage: 'url(/logo.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                onClick={() => navigate('/')}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} xl={4}>
              <FormControl fullWidth>
                <InputLabel id="select-label" color="secondary">
                  {local.appbar.apartment}
                </InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  color="secondary"
                  size="small"
                  value={selectedApartment?.key}
                  label={local.appbar.apartment}
                  onChange={onChangeApartment}
                >
                  {apartments.map((apartment: Apartment) => (
                    <MenuItem key={apartment.key} value={apartment.key}>
                      {apartment.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} md={2} xl={2}>
              <MobileDatePicker
                closeOnSelect
                disablePast
                showDaysOutsideCurrentMonth
                slotProps={{ textField: { fullWidth: true, color: 'secondary', size: 'small' } }}
                label={local.appbar.start}
                value={start}
                onChange={(newValue) => {
                  setStart(newValue || dayjs());
                  if (newValue?.add(3, 'day').isAfter(end)) {
                    setEnd(newValue?.add(3, 'day') || dayjs().add(3, 'day'));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} xl={2}>
              <MobileDatePicker
                closeOnSelect
                disablePast
                showDaysOutsideCurrentMonth
                slotProps={{ textField: { fullWidth: true, color: 'secondary', size: 'small' } }}
                label={local.appbar.end}
                minDate={start.add(3, 'day')}
                value={end}
                onChange={(newValue) => setEnd(newValue || dayjs().add(3, 'day'))}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} xl={2}>
              <Button fullWidth color="secondary" variant="contained" onClick={onRequest}>
                {local.appbar.request}
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
}
