import { getStorage, ref, uploadBytes, uploadString, getDownloadURL, deleteObject } from 'firebase/storage';
import app from '../config/firebase';

const storage = getStorage(app);

export default class Storage {
  static getDownloadURL(path) {
    return getDownloadURL(ref(storage, path));
  }

  static uploadBytes(path, data) {
    return uploadBytes(ref(storage, path), data);
  }

  static uploadString(path, data) {
    return uploadString(ref(storage, path), data);
  }

  static deleteObject(path) {
    return deleteObject(ref(storage, path));
  }
}
