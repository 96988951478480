export default class Apartment {
  key: string;
  bedrooms: number;
  checkin: string;
  checkout: string;
  description: string;
  guests: number;
  images: JSON;
  keywords: JSON;
  name: string;
  price: number;
  priceInfo: string;
  size: number;
  storno: string;
  title: string;

  static factory(data: any) {
    return Object.entries(data).map(
      ([key, values]: [string, any]) =>
        new Apartment(
          key,
          values.bedrooms,
          values.checkin,
          values.checkout,
          values.description,
          values.guests,
          values.images,
          values.keywords,
          values.name,
          values.price,
          values.priceInfo,
          values.size,
          values.storno,
          values.title
        )
    );
  }

  constructor(
    key: string,
    bedrooms: number,
    checkin: string,
    checkout: string,
    description: string,
    guests: number,
    images: JSON,
    keywords: JSON,
    name: string,
    price: number,
    priceInfo: string,
    size: number,
    storno: string,
    title: string
  ) {
    this.key = key;
    this.bedrooms = bedrooms;
    this.checkin = checkin;
    this.checkout = checkout;
    this.description = description;
    this.guests = guests;
    this.images = images;
    this.keywords = keywords;
    this.name = name;
    this.price = price;
    this.priceInfo = priceInfo;
    this.size = size;
    this.storno = storno;
    this.title = title;
  }
}
