import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { createTheme, Theme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { deDE as muiMaterialDeDE } from '@mui/material/locale';
import { deDE as muiXDataGridDeDE } from '@mui/x-data-grid-pro';
import { LocalizationProvider, deDE as muiXDatePickersDeDE } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Box } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import Database from './services/Database';
import Apartment from './classes/Apartment';
import Loading from './components/Loading';
import Viewer from './components/Viewer/Viewer';
import Editor from './components/Editor/Editor';
import Bookings from './pages/bookings/Bookings';
import Login from './pages/auth/Login';

import AppContext from './AppContext';
import AppBar from './AppBar';
import AppFooter from './AppFooter';
import Settings from './pages/settings/Settings';
import Bookingrequest from './pages/bookingrequest/Bookingrequest';

import 'dayjs/locale/de';

export default function App() {
  const [theme, setTheme] = React.useState<Theme | null>(null);
  const [local, setLocal] = React.useState<any | null>(null);
  const [user, setUser] = React.useState<any | null>(null);
  const [apartments, setApartments] = React.useState<Apartment[] | null>(null);
  const [selectedApartment, setSelectedApartment] = React.useState<Apartment | undefined>();
  const [start, setStart] = React.useState<Dayjs>(dayjs());
  const [end, setEnd] = React.useState<Dayjs>(dayjs().add(3, 'day'));
  const [vicinity, setVicinity] = React.useState<any | null>(null);
  const navigate = useNavigate();
  const context = React.useMemo(
    () => ({
      user,
      apartments,
      selectedApartment,
      start,
      end,
      setSelectedApartment,
      setStart,
      setEnd,
      local,
    }),
    [user, apartments, end, local, selectedApartment, start]
  );

  React.useEffect(() => {
    Database.onValue('/theme', (data: ThemeOptions) => setTheme(createTheme(data, muiMaterialDeDE, muiXDataGridDeDE, muiXDatePickersDeDE)));
    Database.onValue('/local', (data: any) => setLocal(data));
    Database.onValue('/vicinity', (data: any) => setVicinity(data));
    Database.onValue('/apartments', (data: any) => {
      const foundApartments = Apartment.factory(data);
      setSelectedApartment(foundApartments[0]);
      setApartments(foundApartments);
    });
  }, []);

  const onLogin = (userAuth: any) => setUser(userAuth);

  if (!theme || !local || !apartments) {
    return <Loading />;
  }
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <AppContext.Provider value={context}>
          <AppBar onRequest={() => navigate('/anfrage')} />
          <Box height="110px" />
          <Routes>
            <Route path="/" element={<Navigate to={`/${apartments[0].key}`} />} />
            <Route path="buchungen" element={user ? <Bookings /> : <Login onLogin={onLogin} />} />
            <Route path="einstellungen" element={user ? <Settings /> : <Login onLogin={onLogin} />} />
            <Route path="anfrage" element={<Bookingrequest />} />
            {apartments.map((apartment: Apartment) => (
              <>
                <Route path={apartment.key} element={<Viewer apartment={apartment} vicinity={vicinity} />} />
                <Route
                  path={`${apartment.key}/bearbeiten`}
                  element={user ? <Editor apartment={apartment} vicinity={vicinity} /> : <Login onLogin={onLogin} />}
                />
              </>
            ))}
          </Routes>
          <AppFooter />
        </AppContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
