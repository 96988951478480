import React from 'react';
import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from '@mui/material';

import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import Apartment from '../../classes/Apartment';
import AppContext from '../../AppContext';
import Database from '../../services/Database';

export default function Bookingrequest() {
  const { apartments, selectedApartment, local, start, setStart, end, setEnd, setSelectedApartment } = React.useContext(AppContext);
  const [name, setName] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [guests, setGuests] = React.useState<string>(selectedApartment.guests);
  const [message, setMessage] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const onSubmit = (e: any) => {
    e.preventDefault();
    Database.push('/requests', {
      apartmentkey: selectedApartment?.key,
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
      name,
      phone,
      email,
      guests,
      message,
      date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      status: 'request',
    });
    setOpen(true);
  };

  return (
    <Container component="form" onSubmit={onSubmit}>
      <Typography variant="h1" color="secondary" sx={{ mt: 9 }}>
        Apartment anfragen
      </Typography>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Paper
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 4,
          }}
        >
          <Typography>Vielen Dank für Ihre Anfrage, wir melden uns so schnell wie möglich!</Typography>
          <Button fullWidth variant="contained" sx={{ mt: 5 }} onClick={() => navigate(`/${selectedApartment.key}`)}>
            Zurück zum Apartment
          </Button>
        </Paper>
      </Modal>
      <Grid container spacing={2} sx={{ mt: 2, mb: 12 }}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="select-label" color="secondary">
              {local.appbar.apartment}
            </InputLabel>
            <Select
              labelId="select-label"
              id="select"
              color="secondary"
              value={selectedApartment?.key}
              label={local.appbar.apartment}
              onChange={(e) => setSelectedApartment(apartments.find((apartment: Apartment) => apartment.key === e.target.value))}
            >
              {apartments.map((apartment: Apartment) => (
                <MenuItem key={apartment.key} value={apartment.key}>
                  {apartment.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <MobileDatePicker
            closeOnSelect
            disablePast
            showDaysOutsideCurrentMonth
            slotProps={{ textField: { fullWidth: true, color: 'secondary' } }}
            label={local.appbar.start}
            value={start}
            onChange={(newValue) => {
              setStart(newValue || dayjs());
              if (newValue?.add(3, 'day').isAfter(end)) {
                setEnd(newValue?.add(3, 'day') || dayjs().add(3, 'day'));
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MobileDatePicker
            closeOnSelect
            disablePast
            showDaysOutsideCurrentMonth
            slotProps={{ textField: { fullWidth: true, color: 'secondary' } }}
            label={local.appbar.end}
            minDate={start.add(3, 'day')}
            value={end}
            onChange={(newValue) => setEnd(newValue || dayjs().add(3, 'day'))}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField fullWidth required color="secondary" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField fullWidth required type="number" color="secondary" label="Gäste" value={guests} onChange={(e) => setGuests(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth required color="secondary" label="Telefonnummer" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth required type="email" color="secondary" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField fullWidth color="secondary" label="Nachricht" value={message} onChange={(e) => setMessage(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box display="flex" justifyContent="end">
            <Button type="submit" variant="contained">
              unverbindlich Anfragen
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
