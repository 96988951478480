import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import app from '../config/firebase';

const auth = getAuth(app);

export default class Authentication {
  static signIn(email: string, password: string, next: any) {
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => next({ user }))
      .catch((error) => next({ error }));
  }
  static signOut(next: any) {
    signOut(auth)
      .then(() => next())
      .catch((error) => next({ error }));
  }
}
