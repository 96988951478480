import { Box, Chip, Container, Divider, Grid, Link, Paper, Typography } from '@mui/material';
import { Masonry } from '@mui/lab';
import ImageGallery from 'react-image-gallery';

import Apartment from '../../classes/Apartment';

import 'react-image-gallery/styles/css/image-gallery.css';

interface Props {
  apartment: Apartment;
  vicinity: any;
}

export default function Viewer({ apartment, vicinity }: Props) {
  return (
    <Container sx={{ mb: 2 }}>
      <Typography variant="h1" color="secondary">{`Apartment "${apartment.name}"`}</Typography>
      <Divider sx={{ mb: 1 }} />
      <Chip color="primary" label={`Größe ${apartment.size}m²`} />
      <Chip color="primary" label={`${apartment.bedrooms} Schlafzimmer`} />
      <Chip color="primary" label={`ideal für ${apartment.guests} Gäste`} />
      <Chip color="primary" label={`Preis €${apartment.price} / Nacht`} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Paper sx={{ mt: 1, ml: 'auto', mr: 'auto' }}>
            <ImageGallery items={apartment.images ? Object.values(apartment.images).sort((a, b) => (a.sort || 0) - (b.sort || 0)) : []} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box display="flex" flexDirection="column" height="100%">
            <Typography variant="h6" color="secondary" sx={{ mt: 1, mb: 3 }}>
              {apartment.title}
            </Typography>
            <Typography align="justify">{apartment.description}</Typography>
            <Box flexGrow={1} />
            <Grid container sx={{ mt: 2, p: 1, backgroundColor: 'primary.main' }}>
              <Grid item xs={12} sm={4}>
                <Typography>PREIS / NACHT:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography>{`€ ${apartment.price}`}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography>SCHLAFZIMMER:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography>{apartment.bedrooms}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography>MAX. GÄSTE:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography>{apartment.guests}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography>GRÖSSE:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography>{`${apartment.size} m²`}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography>CHECK IN:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography>{apartment.checkin}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography>CHECK OUT:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography>{apartment.checkout}</Typography>
              </Grid>
            </Grid>
            <Box flexGrow={3} />
            {apartment.storno &&
              apartment.storno.split(';').map((storno) => (
                <Typography key={storno} variant="caption" align="center">
                  {storno}
                </Typography>
              ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Typography variant="h2" color="secondary" sx={{ mb: 2 }}>
            Ausstattung
          </Typography>
          <Masonry columns={{ xs: 1, sm: 2, lg: 4 }} spacing={2}>
            {apartment.keywords &&
              Object.entries(apartment.keywords)
                .sort((a, b) => (a[1].sort || 0) - (b[1].sort || 0))
                .map(([key, values]: [string, any]) => (
                  <Box key={key}>
                    <Typography color="secondary">{values.name}</Typography>
                    {/* <Divider /> */}
                    <ul>
                      {values.list?.split(';').map((word: string) => (
                        <li key={`${key}-${word}`}>
                          <Typography variant="caption">{word}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Box>
                ))}
          </Masonry>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Link color="inherit" href="http://www.neutal.at/Tourismus_Freizeit" target="_blank" sx={{ textDecoration: 'none' }}>
            <Typography variant="h2" color="secondary">
              Neutal entdecken!
            </Typography>
            <Box sx={{ backgroundImage: 'url(slider-tourismus.jpg)' }}>
              <Box sx={{ maxWidth: '323px', background: 'rgb(255 255 255 / 75%)', p: 2, position: 'relative', top: 0, right: 0 }}>
                <Typography paragraph variant="caption" align="justify" sx={{ mb: 0 }}>
                  Im neu renovierten Waldbad erlebst du gemeinsam mit deiner Familie actionreiche Abenteuer. Genießt das erfrischende Sprungbecken,
                  saust die Großwasserrutsche hinunter und plantscht im speziellen Kinderbereich. Fordert euch zu spaßigen Beachvolleyball- und
                  Soccer-Matches heraus und erlebt sportliche Aktivitäten unter freiem Himmel. Entdeckt die faszinierende Baukultur im MUBA Museum und
                  lasst die kleinen Abenteurer in der Kletterhalle im Vereinszentrum Neutal neue Herausforderungen meistern.
                </Typography>
              </Box>
            </Box>
          </Link>
        </Grid>
        {vicinity && (
          <Grid item xs={12}>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Typography variant="h2" color="secondary">
              In der Umgebung
            </Typography>
            <Masonry columns={{ xs: 1, sm: 2, lg: 3 }} spacing={2}>
              {Object.entries(vicinity).map(([vicinityCategoryKey, vicinityCategory]: [string, any]) => (
                <Box key={vicinityCategoryKey}>
                  <Typography color="secondary" variant="h6">
                    {vicinityCategory.name}
                  </Typography>
                  {vicinityCategory.children &&
                    Object.entries(vicinityCategory.children).map(([vicinityItemKey, vicinityItem]: [string, any]) => (
                      <Box key={`${vicinityCategoryKey}-${vicinityItemKey}`} display="flex">
                        <Typography variant="subtitle2">{vicinityItem.name}</Typography>
                        <Box flexGrow={1} />
                        <Typography variant="subtitle2">{vicinityItem.distance}</Typography>
                      </Box>
                    ))}
                </Box>
              ))}
            </Masonry>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
