import { Email, Map, Phone, Person } from '@mui/icons-material';
import { Box, Button, Container, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function AppFooter() {
  const navigate = useNavigate();

  return (
    <Container disableGutters maxWidth={false}>
      <Box width="100%" sx={{ backgroundColor: 'primary.main', p: 2 }}>
        <Box display="flex" width="100%" justifyContent="center">
          <Button
            sx={{
              height: '180px',
              width: '300px',
              backgroundImage: 'url(/logo.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              mr: 2,
            }}
            onClick={() => navigate('/')}
          />
          <Box>
            <Typography paragraph variant="h6">
              KONTAKT
            </Typography>
            <Typography variant="caption">
              <Box display="flex" gap={1}>
                <Person />
                <Typography paragraph variant="caption">
                  Sabine Bauer
                </Typography>
              </Box>
            </Typography>
            <Link href="callto:+4367764334387" target="_blank" color="inherit">
              <Box display="flex" gap={1}>
                <Phone />
                <Typography paragraph variant="caption">
                  +43 677 / 643 343 87
                </Typography>
              </Box>
            </Link>
            <Link href="mailto:office@neutal-ferienapartments.at" target="_blank" color="inherit">
              <Box display="flex" gap={1}>
                <Email />
                <Typography paragraph variant="caption">
                  office@neutal-ferienapartments.at
                </Typography>
              </Box>
            </Link>
            <Link
              href="https://www.google.com/maps/place/Burgenland+Str.+100,+7343+Neutal/@47.5458529,16.4445297,18z/data=!4m6!3m5!1s0x476c2775ea3c4e81:0xc5df76decea8c928!8m2!3d47.5458529!4d16.4451804!16s%2Fg%2F11f54m6x7h?entry=ttu"
              target="_blank"
              color="inherit"
            >
              <Box display="flex" gap={1}>
                <Map />
                <Typography paragraph variant="caption">
                  Hauptstraße 100, 7343 Neutal, Österreich
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
        <Box display="flex" width="100%" justifyContent="center" sx={{ mt: 2 }}>
          <Typography variant="caption">Copyright © All Rights Reserved</Typography>
        </Box>
      </Box>
    </Container>
  );
}
