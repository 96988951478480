import React from 'react';
import { Button, Container, Typography } from '@mui/material';

import dayjs from 'dayjs';
import { DataGridPro } from '@mui/x-data-grid-pro';
import AppContext from '../../AppContext';
import Apartment from '../../classes/Apartment';
import Database from '../../services/Database';

export default function Organizer() {
  const { apartments } = React.useContext(AppContext);
  const [requests, setRequests] = React.useState<any>();

  React.useEffect(() => Database.onValue('/requests', (data: any) => setRequests(data)), []);

  const mapRows = React.useMemo(() => {
    if (apartments && requests) {
      return Object.entries(requests).map(([key, values]: [string, any]) => ({
        id: key,
        apartment: apartments.find((apartment: Apartment) => apartment.key === values.apartmentkey)?.name,
        start: values.start,
        end: values.end,
        name: values.name,
        email: values.email,
        phone: values.phone,
        guests: values.guests,
        message: values.message,
        date: values.date,
        status: values.status,
      }));
    }
    return [];
  }, [apartments, requests]);

  return (
    <Container>
      <Typography variant="h1" color="secondary" sx={{ mt: 2 }}>
        Buchungen
      </Typography>
      <DataGridPro
        sx={{ mt: 2, mb: 5 }}
        columns={[
          { field: 'apartment', headerName: 'Apartment' },
          { field: 'name', headerName: 'Name' },
          { field: 'start', headerName: 'Checkin' },
          { field: 'end', headerName: 'Checkout' },
          { field: 'email', headerName: 'Email' },
          { field: 'phone', headerName: 'Telefon' },
          { field: 'guests', headerName: 'Gäste' },
          { field: 'message', headerName: 'Nachricht' },
          { field: 'date', headerName: 'Buchungsdatum' },
          { field: 'status', headerName: 'Status' },
        ]}
        rows={mapRows}
      />
    </Container>
  );
}
