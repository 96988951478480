import { getDatabase, ref, onValue, update, push, remove } from 'firebase/database';
import app from '../config/firebase';

const database = getDatabase(app);

export default class Database {
  static onValue(path, next) {
    onValue(ref(database, path), (snapshot) => next(snapshot.val()));
  }

  static update(path, data) {
    return update(ref(database, path), data);
  }

  static push(path, data) {
    return push(ref(database, path), data);
  }

  static delete(path) {
    return remove(ref(database, path));
  }
}
