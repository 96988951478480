import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Divider, Grid, IconButton, Paper, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import TextField from '../TextField';
import Apartment from '../../classes/Apartment';
import Storage from '../../services/Storage';
import Database from '../../services/Database';

interface Props {
  apartment: Apartment;
  vicinity: any;
}

export default function Editor({ apartment, vicinity }: Props) {
  const onImagePush = async (event: any) => {
    const file = event.target.files[0];
    const { key } = await Database.push(`/apartments/${apartment.key}/images`, { name: file.name });
    await Storage.uploadBytes(`/apartments/${apartment.key}/${key}/original`, file);
    const url = await Storage.getDownloadURL(`/apartments/${apartment.key}/${key}/original`);
    Database.update(`/apartments/${apartment.key}/images/${key}`, { original: url, thumbnail: url });
  };

  const onChange = (field: string, value: any) => Database.update(`/apartments/${apartment.key}`, { [field]: value });
  const onChangeKeywords = (key: string, field: string, value: any) =>
    Database.update(`/apartments/${apartment.key}/keywords/${key}`, { [field]: value });
  const onDeleteKeywords = (key: string) => Database.delete(`/apartments/${apartment.key}/keywords/${key}`);
  const onPushKeyword = () => Database.push(`/apartments/${apartment.key}/keywords`, { name: 'Neue Liste', list: '' });

  const onChangeImages = (key: string, field: string, value: any) =>
    Database.update(`/apartments/${apartment.key}/images/${key}`, { [field]: value });

  const onDeleteImage = (key: string) => {
    Storage.deleteObject(`/apartments/${apartment.key}/${key}/original`);
    Database.delete(`/apartments/${apartment.key}/images/${key}`);
  };

  const onPushVicinity = () => Database.push(`/vicinity`, { name: 'Neue Liste' });
  const onChangeVicinity = (key: string, field: string, value: any) => Database.update(`/vicinity/${key}`, { [field]: value });
  const onDeleteVicinity = (key: string) => Database.delete(`/vicinity/${key}`);

  const onPushVicinityChild = (key: string) => Database.push(`/vicinity/${key}/children`, { name: 'Neues Element' });
  const onChangeVicinityChild = (key: string, child: string, field: string, value: any) =>
    Database.update(`/vicinity/${key}/children/${child}`, { [field]: value });
  const onDeleteVicinityChild = (key: string, child: string) => Database.delete(`/vicinity/${key}/${child}`);

  return (
    <Container>
      <Typography variant="h1" color="secondary">
        Bearbeiten
      </Typography>
      <Divider sx={{ mb: 5 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h1" color="secondary">{`Apartment "${apartment.name}"`}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            color="secondary"
            label="Name"
            value={apartment.name}
            onChange={(value: any) => onChange('name', value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="secondary" sx={{ mt: 1, mb: 3 }}>
            {apartment.title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            color="secondary"
            label="Title"
            value={apartment.title}
            onChange={(value: any) => onChange('title', value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography align="justify">{apartment.description}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            multiline
            variant="outlined"
            color="secondary"
            label="Beschreibung"
            value={apartment.description}
            onChange={(value: any) => onChange('description', value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} container sx={{ mt: 2, p: 1, backgroundColor: 'primary.main' }}>
          <Grid item xs={12} sm={4}>
            <Typography>PREIS / NACHT:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{`€ ${apartment.price}`}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>SCHLAFZIMMER:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{apartment.bedrooms}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>MAX. GÄSTE:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{apartment.guests}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>GRÖSSE:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{`${apartment.size} m²`}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>CHECK IN:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{apartment.checkin}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>CHECK OUT:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{apartment.checkout}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            type="number"
            variant="outlined"
            color="secondary"
            label="PREIS / NACHT"
            value={apartment.price}
            onChange={(value: any) => onChange('price', value)}
          />
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            type="number"
            variant="outlined"
            color="secondary"
            label="SCHLAFZIMMER"
            value={apartment.bedrooms}
            onChange={(value: any) => onChange('bedrooms', value)}
          />
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            type="number"
            variant="outlined"
            color="secondary"
            label="MAX. GÄSTE"
            value={apartment.guests}
            onChange={(value: any) => onChange('guests', value)}
          />
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            type="number"
            variant="outlined"
            color="secondary"
            label="GRÖSSE"
            value={apartment.size}
            onChange={(value: any) => onChange('size', value)}
          />
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            color="secondary"
            label="CHECK IN"
            value={apartment.checkin}
            onChange={(value: any) => onChange('checkin', value)}
          />
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            color="secondary"
            label="CHECK OUT"
            value={apartment.checkout}
            onChange={(value: any) => onChange('checkout', value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column">
            {apartment.storno &&
              apartment.storno.split(';').map((storno) => (
                <Typography key={storno} variant="caption" align="center">
                  {storno}
                </Typography>
              ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            multiline
            variant="outlined"
            color="secondary"
            label="Storno ( Für Zeilenumbruch ';' einfügen!)"
            value={apartment.storno}
            onChange={(value: any) => onChange('storno', value)}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Box display="flex" alignItems="center">
        <Typography variant="h2" color="secondary" sx={{ mb: 2 }}>
          Ausstattung
        </Typography>
        <Box flexGrow={1} />
        <Button variant="contained" onClick={onPushKeyword}>
          <Add sx={{ mr: 1 }} />
          <Typography>Liste hinzufügen</Typography>
        </Button>
      </Box>
      <Grid container spacing={2}>
        {apartment.keywords &&
          Object.entries(apartment.keywords)
            .sort((a, b) => (a[1].sort || 0) - (b[1].sort || 0))
            .map(([key, values]: [string, any]) => (
              <>
                <Grid key={key} item xs={12} sm={6}>
                  <Typography color="secondary">{values.name}</Typography>
                  <ul>
                    {values.list?.split(';').map((word: string) => (
                      <li key={`${key}-${word}`}>
                        <Typography variant="caption">{word}</Typography>
                      </li>
                    ))}
                  </ul>
                </Grid>
                <Grid key={`${key}-edit`} item xs={12} sm={6}>
                  <Accordion>
                    <AccordionSummary>
                      <Box sx={{ width: '100%' }} display="flex" alignItems="center">
                        <Typography color="secondary">{values.name}</Typography>
                        <Box flexGrow={1} />
                        <IconButton onClick={() => onDeleteKeywords(key)}>
                          <Delete />
                        </IconButton>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TextField
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        label="Name"
                        value={values.name}
                        onChange={(value: any) => onChangeKeywords(key, 'name', value)}
                      />
                      <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        color="secondary"
                        label="Liste (Aufzählung durch ';' trennen)"
                        sx={{ mt: 2 }}
                        value={values.list}
                        onChange={(value: any) => onChangeKeywords(key, 'list', value)}
                      />
                      <TextField
                        fullWidth
                        sx={{ mt: 2 }}
                        type="number"
                        variant="outlined"
                        color="secondary"
                        label="Sortierung"
                        value={values.sort || 0}
                        onChange={(value: any) => onChangeKeywords(key, 'sort', value)}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </>
            ))}
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box display="flex" alignItems="center">
        <Typography variant="h2" color="secondary" sx={{ mb: 2 }}>
          Bilder
        </Typography>
        <Box flexGrow={1} />
        <Button variant="contained" component="label">
          <Add sx={{ mr: 1 }} />
          <Typography>Bild Hochladen</Typography>
          <input hidden type="file" accept="image/*" onChange={onImagePush} />
        </Button>
      </Box>
      <Box display="flex" flexWrap="wrap" justifyContent="center" gap={2} sx={{ mb: 5 }}>
        {apartment.images &&
          Object.entries(apartment.images)
            .sort((a, b) => (a[1].sort || 0) - (b[1].sort || 0))
            .map(([key, values]: [string, any]) => (
              <Paper key={key} sx={{ position: 'relative', maxHeight: '200px', maxWidth: '200px' }}>
                <img src={values.original} alt={key} height="100%" width="100%" />
                <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => onDeleteImage(key)}>
                  <Delete />
                </IconButton>
                <TextField
                  type="number"
                  color="secondary"
                  value={values.sort || 0}
                  onChange={(value: any) => onChangeImages(key, 'sort', value)}
                  sx={{ position: 'absolute', bottom: 0, left: 0 }}
                />
              </Paper>
            ))}
      </Box>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Box display="flex" alignItems="center">
        <Typography variant="h2" color="secondary" sx={{ mb: 2 }}>
          In der Umgebung
        </Typography>
        <Box flexGrow={1} />
        <Button variant="contained" onClick={onPushVicinity}>
          <Add sx={{ mr: 1 }} />
          <Typography>Liste hinzufügen</Typography>
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ mb: 10 }}>
        {vicinity &&
          Object.entries(vicinity)
            .sort((a: any, b: any) => (a[1].sort || 0) - (b[1].sort || 0))
            .map(([key, values]: [string, any]) => (
              <>
                <Grid key={key} item xs={12} sm={6}>
                  <Typography color="secondary">{values.name}</Typography>
                  <ul>
                    {values.children &&
                      Object.entries(values.children).map(([childKey, childValues]: [string, any]) => (
                        <Box component="li" key={`${key}-${childKey}`} display="flex">
                          <Typography variant="caption">{childValues.name}</Typography>
                          <Box flexGrow={1} />
                          <Typography variant="caption">{childValues.distance}</Typography>
                        </Box>
                      ))}
                  </ul>
                </Grid>
                <Grid key={`${key}-edit`} item xs={12} sm={6}>
                  <Accordion>
                    <AccordionSummary>
                      <Box sx={{ width: '100%' }} display="flex" alignItems="center">
                        <Typography color="secondary">{values.name}</Typography>
                        <Box flexGrow={1} />
                        <IconButton onClick={() => onDeleteVicinity(key)}>
                          <Delete />
                        </IconButton>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TextField
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        label="Name"
                        value={values.name}
                        onChange={(value: any) => onChangeVicinity(key, 'name', value)}
                      />
                      <TextField
                        fullWidth
                        sx={{ mt: 2 }}
                        type="number"
                        variant="outlined"
                        color="secondary"
                        label="Sortierung"
                        value={values.sort || 0}
                        onChange={(value: any) => onChangeVicinity(key, 'sort', value)}
                      />
                      {values.children &&
                        Object.entries(values.children).map(([childKey, childValues]: [string, any]) => (
                          <Grid container key={`${key}-${childKey}-edit`} spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={8}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                label="Name"
                                value={childValues.name}
                                onChange={(value: any) => onChangeVicinityChild(key, childKey, 'name', value)}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                label="Distanz"
                                value={childValues.distance}
                                onChange={(value: any) => onChangeVicinityChild(key, childKey, 'distance', value)}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton onClick={() => onDeleteVicinityChild(key, childKey)} sx={{ mt: 1 }}>
                                <Delete />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                      <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                        <Button variant="contained" onClick={() => onPushVicinityChild(key)}>
                          <Add sx={{ mr: 1 }} />
                          <Typography>Element hinzufügen</Typography>
                        </Button>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </>
            ))}
      </Grid>
    </Container>
  );
}
