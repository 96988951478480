const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyD1HDzkEHXKnBO52JEww6hizPmYl3fXqkA',
  authDomain: 'easybooking-27fef.firebaseapp.com',
  databaseURL: 'https://easybooking-27fef-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'easybooking-27fef',
  storageBucket: 'easybooking-27fef.appspot.com',
  messagingSenderId: '582418285742',
  appId: '1:582418285742:web:bc17b44fed7e50bb84993f',
  measurementId: 'G-HSG6H6Q9DV',
};
export default FIREBASE_CONFIG;
