import React from 'react';
import MuiTextField from '@mui/material/TextField';

interface Props {
  value: any;
  type?: string;
  label?: string;
  onChange: (value: string) => void;
  sx?: any;
  fullWidth?: boolean;
  multiline?: boolean;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
}

export default function TextField({ value, type, label, onChange, sx, fullWidth, multiline, variant, color }: Props) {
  const [valueState, setValueState] = React.useState<string>(value);
  const [typingTimout, setTypingTimout] = React.useState<any>();

  React.useEffect(() => setValueState(value), [value]);

  const onChangeTimeout = (event: any) => {
    clearTimeout(typingTimout);
    setTypingTimout(setTimeout(() => onChange(event.target.value), 1000));
    setValueState(event.target.value);
  };

  return (
    <MuiTextField
      fullWidth={fullWidth}
      type={type}
      multiline={multiline}
      variant={variant}
      color={color}
      label={label}
      value={valueState}
      onChange={onChangeTimeout}
      sx={sx}
    />
  );
}
