import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import Authentication from '../../services/Authentication';

interface Props {
  onLogin: (userAuth: any) => void;
}

export default function Login({ onLogin }: Props) {
  const [remember, setRemember] = useState(localStorage.getItem('remember') === 'true');
  const [email, setEmail] = useState(localStorage.getItem('remember') === 'true' ? localStorage.getItem('email') : '');
  const [password, setPassword] = useState('');
  // const [alert, setAlert] = useState();
  // const navigate = useNavigate();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    localStorage.setItem('remember', remember.toString());
    if (email) {
      if (remember) {
        localStorage.setItem('email', email);
      }
      if (password) {
        Authentication.signIn(email, password, (result: any) => {
          const { user, error } = result;
          if (error) {
            console.log(error);
          } else if (user) {
            onLogin(user);
          }
        });
      }
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper sx={{ mt: 12, mb: 14, p: 4 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ m: 1, backgroundColor: 'primary.main' }}>
            <LockOpenIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
            Anmelden
          </Typography>
          <Box component="form" onSubmit={onSubmit}>
            <TextField
              fullWidth
              autoFocus
              required
              color="secondary"
              margin="dense"
              id="email"
              name="email"
              type="email"
              label="Email"
              value={email}
              // error={alert && alert.code === 404}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              required
              fullWidth
              color="secondary"
              margin="dense"
              id="password"
              name="password"
              type="password"
              label="Passwort"
              value={password}
              // error={alert && alert.code === 401}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel control={<Checkbox checked={remember} onChange={(e) => setRemember(e.target.checked)} />} label="Email merken?" />
            <Button fullWidth sx={{ mt: 2 }} color="primary" variant="contained" type="submit">
              Anmelden
            </Button>
            {/* <Button fullWidth sx={{ mt: 1 }} color="primary" onClick={() => navigate('/password/forgotten')}>{local.passwordForgotten}</Button> */}
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
